import React, { useState } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Query } from "../../types/graphql-types"
import { Link } from "gatsby"
import "../components/News/style.css"
import { format } from "date-fns"
import PageTitle from "../components/utils/PageTitle"
import { ja_description } from "../components/const"

const defaultViewNewsListLength = 10
const addNewsListLength = 5

// VNLL=viewNewsListLength
// ANLL=AllNewsListLength
const isVNLLOrHigherANLL = (
  viewNewsListLength: number,
  addNewsListLength: number,
  allNewsListLength: number
) => {
  return viewNewsListLength + addNewsListLength < allNewsListLength
}

const News: React.FC<PageProps<Query>> = ({ data, location }) => {
  const [viewNewsListLength, setViewNewsListLength] = useState(
    defaultViewNewsListLength
  )
  const allNewsList = data.allMicrocmsNews.edges.map(({ node }, i) => {
    return {
      title: node.title,
      date: node.date,
      index: i,
      newsId: node.newsId
    }
  })
  const allNewsListLength = allNewsList.length
  const sortedAllNewsList = allNewsList.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  )
  const viewNewsList = sortedAllNewsList.slice(0, viewNewsListLength)

  return (
    <Layout selectPageName="News" location={location}>
      <SEO title="News" lang={"ja"} description={ja_description} />
      <PageTitle title={"News"} />
      <div className="margin-left">
        <ul>
          {viewNewsList.map(news => {
            return (
              <div key={news.newsId}>
                <li key={news.newsId} className="news-list-item-wrapper">
                  <div className="news-list-item-date">
                    <p>
                      {news.date
                        ? format(new Date(news.date), "yyyy.MM.dd")
                        : "日付が指定されていません"}
                    </p>
                  </div>
                  <div className="news-list-item">
                    <p>
                      <Link to={`/news/${news.newsId}`}>{news.title}</Link>
                    </p>
                  </div>
                </li>
              </div>
            )
          })}
        </ul>
      </div>
      {viewNewsListLength < allNewsListLength && (
        <div className={"margin-left"}>
          <button
            className={"more-display-button"}
            onClick={() =>
              setViewNewsListLength(
                isVNLLOrHigherANLL(
                  viewNewsListLength,
                  addNewsListLength,
                  allNewsListLength
                )
                  ? viewNewsListLength + addNewsListLength
                  : allNewsListLength
              )
            }
          >
            さらに表示
          </button>
        </div>
      )}
    </Layout>
  )
}
export default News

export const NewsIndexQuery = graphql`
  query NewsIndexQuery {
    allMicrocmsNews(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          date
          body
          newsId
        }
      }
    }
  }
`
